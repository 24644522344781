export const sampleRate = 44100;
export const sampleSize = 16;
export const bufferSize = 1024;
export const numberOfInputChannels = 1;
export const numberOfOutputChannels = 1;

export function getWav(leftchannel, rightchannel, recordingLength) {
  // we flat the left and right channels down
  // Float32Array[] => Float32Array
  const leftBuffer = flattenArray(leftchannel, recordingLength);
  const rightBuffer = flattenArray(rightchannel, recordingLength);

  // we interleave both channels together
  // [left[0],right[0],left[1],right[1],...]
  const interleaved = interleave(leftBuffer, rightBuffer);

  // we create our wav file
  const buffer = new ArrayBuffer(44 + interleaved.length * 2);
  const view = new DataView(buffer);

  // RIFF chunk descriptor
  writeUTFBytes(view, 0, 'RIFF');
  view.setUint32(4, 44 + interleaved.length * 2, true);
  writeUTFBytes(view, 8, 'WAVE');

  // FMT sub-chunk
  writeUTFBytes(view, 12, 'fmt ');
  view.setUint32(16, sampleSize, true); // chunkSize
  view.setUint16(20, 1, true); // wFormatTag
  view.setUint16(22, 2, true); // wChannels: stereo (2 channels)
  view.setUint32(24, sampleRate, true); // dwSamplesPerSec
  view.setUint32(28, sampleRate * 4, true); // dwAvgBytesPerSec
  view.setUint16(32, 4, true); // wBlockAlign
  view.setUint16(34, sampleSize, true); // wBitsPerSample

  // data sub-chunk
  writeUTFBytes(view, 36, 'data');
  view.setUint32(40, interleaved.length * 2, true);

  // write the PCM samples
  let index = 44;
  const volume = 1;

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < interleaved.length; i++) {
    view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);

    index += 2;
  }

  // our final blob
  return new Blob([view], {type: 'audio/wav'});
}

function flattenArray(channelBuffer, length) {
  let offset = 0;

  return channelBuffer.reduce((a, v) => {
    a.set(v, offset);

    offset += v.length;

    return a;
  }, new Float32Array(length));
}

function interleave(leftChannel, rightChannel) {
  const length = leftChannel.length + rightChannel.length;
  const result = new Float32Array(length);

  let inputIndex = 0;
  let index = 0;

  while (index < length) {
    result[index++] = leftChannel[inputIndex];
    result[index++] = rightChannel[inputIndex];

    inputIndex++;
  }

  return result;
}

function writeUTFBytes(view, offset, s) {
  for (let i = 0; i < s.length; i++) {
    view.setUint8(offset + i, s.charCodeAt(i));
  }
}
