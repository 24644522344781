import React from 'react';

import * as bem from 'bem-cn';

import PropTypes from 'prop-types';

import './index.css';

const block = bem('Visualizer');

export class Visualizer extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const canvas = this.ref.current;

    this.width = canvas.clientWidth;
    this.height = canvas.clientHeight;

    this.canvasContext = canvas.getContext('2d');

    const grd = this.canvasContext.createLinearGradient(0, 0, 0, this.height);

    grd.addColorStop(0, '#FFFFFF');
    grd.addColorStop(.25, '#5181b8');
    grd.addColorStop(.5, '#224B7a');
    grd.addColorStop(.75, '#5181b8');
    grd.addColorStop(1, '#FFFFFF');

    this.canvasContext.strokeStyle = grd;

    this.draw(this.props);
  }

  componentWillUpdate({frequencyArray}) {
    this.draw(frequencyArray);
  }

  render() {
    return (
        <canvas
            className={block.mix(this.props.className)()}
            ref={this.ref}
            width='320px'
            height='250px'
        />
    );
  }

  draw(frequencyArray) {
    if (frequencyArray.length < this.width) {
      return;
    }

    let adjustedLength;

    this.canvasContext.beginPath();
    this.canvasContext.clearRect(0, 0, this.width, this.height);

    for (let i = 0; i < this.width; i++) {
      const sample = frequencyArray[
          Math.floor(frequencyArray.length * i / this.width)
          ];

      adjustedLength = Math.floor(sample) - (Math.floor(sample) % 5);

      this.canvasContext.moveTo(i, this.height / 2);
      this.canvasContext.lineTo(i, this.height / 2 - adjustedLength * 0.5);
      this.canvasContext.lineTo(i, this.height / 2 + adjustedLength * 0.5);
      this.canvasContext.moveTo(i, this.height / 2);
    }

    this.canvasContext.moveTo(0, this.height / 2);
    this.canvasContext.lineTo(this.width, this.height / 2);

    this.canvasContext.stroke();
  }
}

Visualizer.propTypes = {
  frequencyArray: PropTypes.object.isRequired,
};

export default Visualizer;
