import {
  bufferSize,
  numberOfInputChannels,
  numberOfOutputChannels,
  sampleRate,
  sampleSize
} from "./wav";

const spportedConstraints = navigator.mediaDevices.getSupportedConstraints();

const defaultAudioConstraints = {
  sampleRate: {
    ideal: sampleRate
  },
  sampleSize: {
    ideal: sampleSize
  },
  volume: {
    ideal: 1
  },
  echoCancellation: {
    ideal: false
  },
  autoGainControl: {
    ideal: false
  },
  noiseSuppression: {
    ideal: false
  },
  latency: {
    ideal: 0
  },
  channelCount: {
    ideal: numberOfInputChannels
  }
};

const audioConstraints = Object.keys(defaultAudioConstraints).reduce((a, v) => {
  if (spportedConstraints[v]) {
    a[v] = defaultAudioConstraints[v];
  }

  return a;
}, {});

const AudioContext = window.AudioContext || window.webkitAudioContext;

let stream = null;
let audioContext = null;
let audioStream = null;
let audioProcessor = null;
let audioAnalyser = null;
let frequencyArray = new Uint8Array(0);

let peakVolume = 0;

let leftchannel = [];
let rightchannel = [];

let recordingLength = 0;

export const defaultStreamData = {
  volume: 0,

  leftchannel,
  rightchannel,

  recordingLength,

  frequencyArray,
};

export function getStream(cb) {
  audioContext = new AudioContext();

  audioProcessor = audioContext.createScriptProcessor(
      bufferSize,
      numberOfInputChannels,
      numberOfOutputChannels
  );

  audioProcessor.connect(audioContext.destination);

  audioAnalyser = audioContext.createAnalyser();

  audioAnalyser.fftSize = bufferSize;

  frequencyArray = new Uint8Array(audioAnalyser.frequencyBinCount);

  window.navigator.mediaDevices.getUserMedia({audio: audioConstraints})
  .then((s) => {
    stream = s;

    audioStream = audioContext.createMediaStreamSource(stream);

    audioStream.connect(audioProcessor);
    audioStream.connect(audioAnalyser);

    audioProcessor.onaudioprocess = (e) => {
      const leftBuffer = e.inputBuffer.getChannelData(0);
      const rightBuffer = e.inputBuffer.getChannelData(
          numberOfInputChannels > 1 ? 1 : 0
      );

      const leftVolume = Math.sqrt(
          leftBuffer.reduce((a, v) => v * v, 0) / leftBuffer.length
      );

      const rightVolume = Math.sqrt(
          rightBuffer.reduce((a, v) => v * v, 0) / rightBuffer.length
      );

      const volume = (leftVolume + rightVolume) / 2;

      peakVolume = Math.max(peakVolume || 0, volume);

      leftchannel.push(new Float32Array(leftBuffer));
      rightchannel.push(new Float32Array(rightBuffer));

      recordingLength += bufferSize;

      audioAnalyser.getByteFrequencyData(frequencyArray);

      cb(null, {
        volume: peakVolume === 0 ? 0 : volume / peakVolume,

        leftchannel,
        rightchannel,

        recordingLength,

        frequencyArray,
      });
    };
  }).catch(cb);
}

export function releaseStream() {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
  }

  if (audioProcessor) {
    audioProcessor.onaudioprocess = null;
  }

  if (audioStream && audioAnalyser) {
    audioStream.disconnect(audioAnalyser);
  }

  if (audioStream && audioProcessor) {
    audioStream.disconnect(audioProcessor);
  }

  if (audioProcessor && audioContext) {
    audioProcessor.disconnect(audioContext.destination);
  }

  if (audioContext) {
    audioContext.close();
  }

  audioAnalyser = null;
  audioProcessor = null;
  audioStream = null;
  audioContext = null;

  frequencyArray = new Uint8Array(0);

  peakVolume = 0;

  leftchannel = [];
  rightchannel = [];

  recordingLength = 0;
}
