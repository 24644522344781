import axios from 'axios';

import connect from '@vkontakte/vkui-connect';

import {guid} from './index';

export async function processAudio(audio) {
  const that = processAudio;

  const result = await search(await audio.toBase64());

  if (!(
      typeof result === 'object' &&
      Array.isArray(result.songs) &&
      result.songs.length > 0
  )) {
    throw new Error('Cannot match track');
  }

  const trackId = result.songs.reduce((a, [trackId, distance]) => {
    if (distance < a.distance) {
      a.distance = distance;
      a.trackId = trackId;
    }

    return a;
  }, {trackId: null, distance: Infinity}).trackId;

  that.accessToken = that.accessToken || await getAccessToken();

  if (!that.accessToken) {
    throw new Error('Cannot get access token');
  }

  const track = await findTrack(trackId, that.accessToken);

  if (!track) {
    throw new Error('Cannot find track');
  }

  return track;
}

function search(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error('Cannot match track')), 5000);

    axios.post(
        'https://oavk-vm130.vkpartner.ru/search',
        JSON.stringify({data}), {
          headers: {
            'Content-Type': 'application/json'
          }
        }
    )
    .then(({data}) => resolve(data))
    .catch(reject);
  });
}

function getAccessToken() {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error('Cannot get access token')), 5000);

    const wait = (e) => {
      if (e.detail.type === 'VKWebAppAccessTokenFailed') {
        connect.unsubscribe(wait);

        reject(new Error('Cannot get access token'));
      } else if (e.detail.type === 'VKWebAppAccessTokenReceived') {
        connect.unsubscribe(wait);

        resolve(e.detail.data.access_token);
      }
    };

    connect.subscribe(wait);

    connect.send(
        'VKWebAppGetAuthToken',
        {'app_id': 7007920, 'scope': 'audio'}
    );
  });
}

function findTrack(trackId, accessToken) {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error('Cannot find track')), 5000);

    const requestId = guid();

    const wait = (e) => {
      if (
          e.detail.type === 'VKWebAppCallAPIMethodFailed' &&
          e.detail.data.request_id === requestId
      ) {
        connect.unsubscribe(wait);

        reject(new Error('Cannot get track'));
      } else if (
          e.detail.type === 'VKWebAppCallAPIMethodResult' &&
          e.detail.data.request_id === requestId
      ) {
        connect.unsubscribe(wait);

        resolve(e.detail.data.response[0]);
      }
    };

    connect.subscribe(wait);

    connect.send(
        'VKWebAppCallAPIMethod',
        {
          'method': 'audio.getById',
          'request_id': requestId,
          'params': {
            'audios': trackId,
            'version': '5.106',
            'access_token': accessToken
          }
        }
    );
  });
}
