import React from 'react';

import * as bem from 'bem-cn';

import PropTypes from 'prop-types';

import './index.css';

const block = bem('Track');

class Track extends React.Component {
  render() {
    const {
      track: {
        title: trackTitle,
        artist: trackArtist,
        url: trackUrl
      }
    } = this.props;

    return (
        <figure className={block()}>
          <figcaption
              className={block('title')()}
          >
            {trackTitle} - {trackArtist}
          </figcaption>
          <audio
              className={block('audio')()}
              controls={true}
              src={trackUrl}
              preload='auto'
          />
        </figure>
    );
  }
}

Track.propTypes = {
  track: PropTypes.object.isRequired,
};

export default Track;
