/**
 * Generate uniq id
 *
 * @returns uniq string
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
  }

  return `x-${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Format time
 *
 * @param time
 */
export function convertTime(time) {
  const z = (n) => `${n < 10 ? '0' : ''}${Math.floor(n)}`;

  const secs = time / 1000;

  return `${z(secs / 3600 | 0)}:${z((secs % 3600) / 60 | 0)}:${z(secs % 60)}.${Math.floor((time % 1000) / 100)}`;
}

