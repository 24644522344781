export class AudioWav {
  params = {};

  constructor(params) {
    this.params = params;
  }

  getUrl() {
    return this.params.url || URL.createObjectURL(this.params.blob);
  }

  getType() {
    return this.params.type;
  }

  getTitle() {
    return this.params.title;
  }

  getDuration() {
    return this.params.duration;
  }

  toBase64() {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result.toString().split('base64,')[1]);
      };

      reader.readAsDataURL(this.params.blob);

      /*
        const url = window.URL.createObjectURL(this.params.blob);

        const audio = document.createElement('audio');
        document.querySelector('.Panel__in-before').innerHTML = `<audio controls><source src="${url}" type="audio/wav"></audio>`;
      */

      const url = window.URL.createObjectURL(this.params.blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.target = '_blank';
      a.href = url;
      a.style = 'display: none';
      a.download = 'test.wav';
      a.click();

      setTimeout(() => a.parentElement.removeChild(a), 1000);

      window.URL.revokeObjectURL(url);
    });
  }
}
