import React from 'react';
import * as bem from 'bem-cn';

import {View, Panel, PanelHeader, Spinner} from '@vkontakte/vkui';

import {AudioWav} from '../../utils/AudioWav';

import {defaultStreamData, getStream, releaseStream} from '../../tools/stream';

import {processAudio} from '../../tools/audio';

import {getWav} from '../../tools/wav';

import Track from '../Track';
import Visualizer from '../Visualizer';
import Microphone from '../Microphone';

import '@vkontakte/vkui/dist/vkui.css';

import './index.css';

export const RecordState = {
  HOLD: 0,
  RECORD: 1,
  WAITING: 2,
};

const block = bem('App');

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);

    this.state = {
      ...defaultStreamData,

      track: null,

      error: null,

      recordState: RecordState.HOLD,

      startRecordTime: Date.now(),

      volume: 0,
    };
  }

  componentWillUnmount() {
    this.releaseStream();
  }

  render() {
    let additionalContent = (
        <div className={block('plug')()}/>
    );

    if (this.state.recordState === RecordState.WAITING) {
      additionalContent = (
          <Spinner size='large' style={{height: '250px'}}/>
      );
    } else if (this.state.track) {
      additionalContent = (
          <Track track={this.state.track}/>
      );
    } else if (this.state.frequencyArray) {
      additionalContent = (
          <Visualizer frequencyArray={this.state.frequencyArray}/>
      );
    }

    return (
        <View activePanel={block()}>
          <Panel id={block()}>
            <PanelHeader>VK Audio Search App</PanelHeader>
            {additionalContent}
            <Microphone
                volume={this.state.volume}
                handleOnClick={this.handleOnClick}

                error={this.state.error}
                recordState={this.state.recordState}
                startRecordTime={this.state.startRecordTime}
            />
          </Panel>
        </View>
    );
  }

  startRecord() {
    this.setState({
      ...defaultStreamData,

      track: null,
      error: null,

      volume: 0,

      recordState: RecordState.RECORD,
      startRecordTime: Date.now(),
    });

    this.createStream();

    this.timer = window.setTimeout(() => this.stopRecord(), 15000);
  }

  stopRecord() {
    const duration = Date.now() - this.state.startRecordTime;

    if (duration > 5000) {
      const blob = getWav(
          this.state.leftchannel,
          this.state.rightchannel,
          this.state.recordingLength,
      );

      this.setState({
        recordState: RecordState.WAITING,
      });

      const audio = new AudioWav({
        type: 'audio/wav',
        blob,
        duration,
      });

      processAudio(audio).then((track) => {
        this.setState({
          track,
          recordState: RecordState.HOLD,
        });
      }).catch((error) => {
        this.setState({
          error,
          recordState: RecordState.HOLD,
        })
      });

    } else {
      this.setState({recordState: RecordState.HOLD});
    }

    this.releaseStream();
  }

  createStream() {
    getStream((error, streamData) => {
      if (error) {
        this.setState({error});

        this.releaseStream();

        return;
      }

      this.setState({...streamData});
    });
  }

  releaseStream() {
    this.setState({...defaultStreamData});

    releaseStream();
  }

  setVolume(volume) {
    this.setState({volume});
  }

  handleOnClick() {
    clearTimeout(this.timer);

    if (this.state.recordState === RecordState.RECORD) {
      this.stopRecord();
    } else {
      this.startRecord();
    }
  }
}

export default App;
