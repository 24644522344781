import React from 'react';

import * as bem from 'bem-cn';

import PropTypes from 'prop-types';

import {guid, convertTime} from '../../tools';

import {RecordState} from '../App';

import './index.css';

const block = bem('Microphone');

class Microphone extends React.Component {
  constructor(props) {
    super(props);

    this.uid = guid();

    this.state = {};
  }

  render() {
    const uid = this.uid;

    const {
      volume,
      handleOnClick
    } = this.props;

    return (
        <div className={block()}>
          <button
              id={uid}
              className={block('button')()}
              onClick={handleOnClick}
          >
            <svg
                className={block('svg', {
                  record: this.props.recordState === RecordState.RECORD,
                })()}
                id='record-button'
                enableBackground='new 0 0 64 64'
                height='64px'
                viewBox='0 0 64 64'
                width='64px'
                xmlSpace='preserve'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
            >
              <defs>
                <clipPath id='clipPath1'>
                  <path
                      d='M32,39.5c3.315,0,6-2.685,6-6v-18c0-3.315-2.685-6-6-6s-6,2.685-6,6v18C26,36.815,28.685,39.5,32,39.5z'
                      fill='#FFF'
                  />
                </clipPath>
              </defs>

              <circle
                  cx='32'
                  cy='32'
                  fill='#77B3D4'
                  r='32'
              />
              <g opacity='0.2'>
                <path
                    d='M32,41.5c3.315,0,6-2.685,6-6v-18c0-3.315-2.685-6-6-6s-6,2.685-6,6v18C26,38.815,28.685,41.5,32,41.5z'
                    fill='#231F20'
                />
                <path
                    d='M32,48.25c-7.168,0-13-5.832-13-13V31.5c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v3.75c0,5.514,4.486,10,10,10s10-4.486,10-10V31.5c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v3.75C45,42.418,39.168,48.25,32,48.25     z'
                    fill='#231F20'
                />
                <path
                    d='M32,55c-0.828,0-1.5-0.672-1.5-1.5v-6c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v6C33.5,54.328,32.828,55,32,55z'
                    fill='#231F20'
                />
                <path
                    d='M37,56H27c-0.828,0-1.5-0.672-1.5-1.5S26.172,53,27,53h10c0.828,0,1.5,0.672,1.5,1.5S37.828,56,37,56z'
                    fill='#231F20'
                />
              </g>

              <g style={{clipPath: 'url(#clipPath1)'}}>
                <path
                    d='M32,39.5c3.315,0,6-2.685,6-6v-18c0-3.315-2.685-6-6-6s-6,2.685-6,6v18C26,36.815,28.685,39.5,32,39.5z'
                    fill='#4F5D73'
                />
                <path
                    transform={`translate(0 ${30 - volume * 30})`}
                    d='M32,39.5c3.315,0,6-2.685,6-6v-18c0-3.315-2.685-6-6-6s-6,2.685-6,6v18C26,36.815,28.685,39.5,32,39.5z'
                    fill='#FFF'
                />
              </g>

              <path
                  d='M32,46.25c-7.168,0-13-5.832-13-13V29.5c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v3.75c0,5.514,4.486,10,10,10s10-4.486,10-10V29.5c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v3.75C45,40.418,39.168,46.25,32,46.25z    '
                  fill='#FFFFFF'
              />
              <path
                  d='M32,53c-0.828,0-1.5-0.672-1.5-1.5v-6c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v6C33.5,52.328,32.828,53,32,53z'
                  fill='#FFFFFF'
              />
              <path
                  d='M37,54H27c-0.828,0-1.5-0.672-1.5-1.5S26.172,51,27,51h10c0.828,0,1.5,0.672,1.5,1.5S37.828,54,37,54z'
                  fill='#FFFFFF'
              />
            </svg>
          </button>
          {this.getMircophoneLabel()}
        </div>
    );
  }

  getMircophoneLabel() {
    const uid = this.uid;

    const {
      error,
      recordState,
      startRecordTime,
    } = this.props;

    if (error) {
      return (
          <label
              className={block('label')()}
              htmlFor={uid}
          >{error.toString()}</label>
      );
    }

    if (recordState === RecordState.HOLD) {
      return (
          <label
              className={block('label')()}
              htmlFor={uid}
          >Нажмите,&nbsp;чтобы&nbsp;узнать<br/>какой&nbsp;сейчас&nbsp;играет&nbsp;трек</label>
      );
    }

    if (recordState === RecordState.RECORD) {
      return (
          <label
              className={block('label')()}
              htmlFor={uid}
          >Слушаю<br/>{convertTime(Date.now() - startRecordTime)}
          </label>
      );
    }
  }
}

Microphone.propTypes = {
  volume: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,

  error: PropTypes.object,
  recordState: PropTypes.number.isRequired,
  startRecordTime: PropTypes.number.isRequired,
};

export default Microphone;
